<template>
  <van-popup v-model="show" >
    <div class="dialog_wrap" >
      <img src="../img/16.png" class="close_icon" @click="handleClose" />
      <div class="title" >修改密码</div>
      <div class="form_item" >
        <div class="form_item_label" >旧密码：</div>
        <input placeholder="请输入旧密码" type="password" class="form_input" v-model="form.oldPassword" />
      </div>
      <div class="form_item" >
        <div class="form_item_label" >新密码：</div>
        <input placeholder="请输入新密码" type="password" class="form_input" v-model="form.password" />
      </div>
      <van-button class="confirm_button" @click="handleConfirm" :loading="submitting" >确认修改</van-button>
    </div>
  </van-popup>
</template>

<script>
import {
  Toast
} from 'vant'
import {
  updatePassword
} from '@/api'
export default {
  name: 'UpdatePwd',
  data() {
    return {
      show: false,
      form: {
        password: '',
        oldPassword: ''
      },
      submitting: false
    }
  },
  methods: {
    handleConfirm() {
      const { form } = this
      console.log(form)
      if(!form.oldPassword) {
        Toast('请输入旧密码')
        return false
      }

      if(!form.password) {
        Toast('请输入确认新密码')
        return false
      }

      this.submitting = true
      updatePassword({...form}).then(res => {
        this.submitting = false
        if(res.success) {
          Toast('密码修改成功')
          setTimeout(() => {
            localStorage.clear()
            window.location.reload()
          }, 2000)
        }else {
          Toast(res.msg)
        }
      })
    },
    handleClose() {
      this.show = false
      this.form = {
        password: '',
        oldPassword: ''
      }
    },
    handleShow() {
      this.show = true
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="less" >
.dialog_wrap {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 320px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  position: relative;

  .close_icon {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .title {
    font-size: 18px;
    text-align: center;
    color: #333;
    font-weight: 500;
    margin: 0 0 30px 0;
  }

  .confirm_button {
    background: linear-gradient(88deg, #6FBDE0, #03CFAF);
    width: 100%;
    border: none;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    margin: 20px 0 0 0;
    height: 40px;
  }

  .form_item {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px 0;

    .form_input {
      height: 100%;
      width: 200px;
      text-align: right;
      border: 1px solid #efefef;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      padding: 0 10px;
      border-radius: 5px;
      font-size: 14px;
    }

    .form_item_label {
      font-size: 15px;
      color: #333;
      width: 65px;
    }
  }
}

</style>

<template>
  <van-popup v-model="show">
    <div class="cash_wrap" >
      <img src="./img/3.png" class="close_icon" @click="show = false" />
      <div class="title" >提现</div>
      <div class="form_item" >
        <div >到账银行卡</div>
        <div class="form_right" v-if="account" >
         {{ account.accountHolder }}（{{ account.bankCardNumber.substring(account.bankCardNumber.length - 4, account.bankCardNumber.length) }}）<img src="./img/1.png" class="enter_icon" />
        </div>
        <div v-else class="error_text" @click="toPage('bank')" >暂未配置银行卡，点击去配置</div>
      </div>
      <div class="money_title" >提现金额</div>
      <div class="money_item" >
        <div class="money_input_wrap" >
          ￥<input class="money_input" placeholder="请输入提现金额" type="number" v-model="drawMoney" @input="moneyInput" />
        </div>
        <div class="commission_text" >手续费：￥{{service}}</div>
      </div>

      <div class="money_item" >
        <div>当前余额 {{ amount }} 元</div>
        <div class="cash_all" @click="drawMoney = amount">全部提现</div>
      </div>

      <div class="rule_title" >
        <img src="./img/2.png" class="tips" />
        提现规则
      </div>
      <div class="rule_text" >
        <div>最低提现金额：{{ serviceMoney.limit }}</div>
        <div>税率：{{ serviceMoney.scale }}%</div>
      </div>
      <van-button class="cash_button" @click="handleCash" :loading="submitting" >确认提现</van-button>
    </div>
  </van-popup>
</template>
<script>
import {
  getServiceMoney,
  getBank,
  cashMoney
} from "@/api";
import { Toast } from 'vant'
export default {
  name: 'CashDialog',
  data() {
    return {
      show: false,
      drawMoney: '',
      serviceMoney: {
        extMoney: 0,
        limit: 0,
        scale: 0
      },
      service: 0,
      submitting: false,
      account: null
    }
  },
  props: {
    amount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    moneyInput(event) {
      let value = event.target.value
      const { serviceMoney } = this
      if(value) {
        this.service = parseFloat(value) * parseFloat(serviceMoney.scale)/100 + parseFloat(serviceMoney.extMoney) + ''
        console.log('service:', this.service)
      }else {
        this.service = 0
      }
    },
    toPage(name) {
      this.show = false
      this.$router.push({
        name
      })
    },
    handleCash() {
      const { drawMoney, serviceMoney, account } = this

      if(!account) {
        Toast('暂无银行卡信息，请前去配置')
        return false
      }

      if(!drawMoney) {
        Toast('请输入提现金额')
        return false
      }
      if(parseFloat(drawMoney) > parseFloat(this.amount)) {
        Toast('提现金额不得大于当前余额')
        return false
      }

      if(parseFloat(drawMoney) < parseFloat(serviceMoney.limit)) {
        Toast('提现金额不得小于' + serviceMoney.limit)
        return false
      }
      this.submitting = true
      cashMoney({ drawMoney }).then(res => {
        this.submitting = false
        if(res.success) {
          Toast('操作成功')
          this.$emit('ok')
          this.show = false
        }else {
          Toast(res.msg)
        }
      })
    },
    handleShow() {
      this.drawMoney = ''
      this.show = true
    },
    getServiceMoney() {
      getServiceMoney({}).then(res => {
        if(res.success) {
          this.serviceMoney = res.data
          console.log(this.serviceMoney)
        }
      })
    },
    getBank() {
      getBank({}).then(res => {
        if(res.success) {
          this.account = res.data
        }
      })
    }
  },
  mounted() {
    this.getServiceMoney()
    this.getBank()
  }
}
</script>
<style scoped lang="less" >
.cash_wrap {
  width: 314px;
  border-radius: 15px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 18px 22px;
  background: #fff;
  position: relative;

  .close_icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 22px;
    top: 26px;
  }

  .cash_button {
    width: 116px;
    height: 43px;
    background: linear-gradient(95deg, #03CFAF, #62BFDA);
    border-radius: 21px;
    border: 1px solid #FFFFFF;
    margin: 20px auto 0;
    line-height: 43px;
    color: #fff;
    display: block;
    font-size: 13px;
    font-weight: 400;
  }

  .rule_text {
    font-weight: 400;
    font-size: 9px;
    color: #909090;
    line-height: 13px;
    margin: 10px 0 0 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0 0 0 14px;
  }

  .rule_title {
    display: flex;
    align-items: center;
    font-size: 11px;
    color: #474747;
    margin: 10px 0 0 0;
    .tips {
      width: 9px;
      height: 9px;
      margin: 0 5px 0 0;
    }
  }

  .money_item {
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    color: #909090;
    border-bottom: 1px solid #ebebeb;

    .cash_all {
      font-size: 13px;
      color: #03CFAF;
      text-decoration: underline;
    }

    .money_input_wrap {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #474747;
      font-weight: 400;

      .commission_text {
        font-size: 11px;
        color: #474747;
        font-weight: 400;
      }

      .money_input::-webkit-input-placeholder {
        color: #909090;
      }

      .money_input {
        color: #474747;
        font-size: 13px;
        border: none;
        height: 100%;
      }
    }
  }

  .money_title {
    height: 47px;
    font-size: 15px;
    color: #474747;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .form_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 47px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: 1px solid #ebebeb;
    font-size: 15px;
    color: #474747;
    font-weight: 400;

    .error_text {
      font-size: 12px;
      color: #ff0000;
    }

    .form_right {
      display: flex;
      align-items: center;

      .enter_icon {
        width: 12px;
        height: 6px;
        margin: 0 0 0 20px;
      }
    }
  }

  .title {
    text-align: center;
    font-size: 18px;
    color: #333;
    font-weight: 500;
  }
}
</style>
